import React from "react";
import "./service-item-card.scss";
import { Heading } from "../Heading/Heading";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const CardServiceItem = ({ title, description, image, icon }) => {
  const parsedImage = getImage(image);
  return (
    <div className="card-service-item">
      {icon && 
        <div className="card-service-item__icon">
          <i className={icon}></i>
        </div>
      }
      {image && 
        <div className="card-service-item__image">
          <GatsbyImage image={parsedImage} alt={description} className="image" />
        </div>
      }
      <div className="card-service-item__data">
        <Heading level="h4">{title}</Heading>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};
